<template>
  <CustomDialog
    ref="dialog"
    title="Edit Link"  
    button1-text="Cancel"
    @button1="close_dialog"
  >
    <template #content>
      <v-row dense class="custom-dialog">
        <v-col cols="12">
          <label class="primary--text subtitle-2">Titles</label>
          <v-text-field
            v-model.trim="title"
            class="dialog__textfield d-field"
            outlined
            clearable
            clear-icon="mdi-close-circle-outline"
            hide-details="auto"
            prepend-inner-icon="text_fields"
            color="#657186"
          />
        </v-col>
        <v-col cols="12">
          <label class="primary--text subtitle-2">URL</label>
          <v-text-field
            class="dialog__link d-field"
            pattern="https://.*"
            type="url"
            v-model.trim="link"
            prepend-inner-icon="link"
            clearable
            clear-icon="mdi-close-circle-outline"
            @keydown="validate_url"
            outlined
            hide-details="auto"
            color="#657186"
          />
        </v-col>
      </v-row>
    </template>
    <template slot="button2">
      <v-btn
        dark
        color="primary"
        class="text-none light--text"
        @click="on_dialog_save"
        :disabled="is_disabled"
      >
        Save
      </v-btn>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
import apiTo from "../api";

export default {
  components: {
    CustomDialog,
  },
  props: {
    id: [Number, String],
  },
  data: () => ({
    link: "",
    title: "",
    valid_url: false,
    reportId: null,
    reportIndex: null,
  }),

  computed: {
    is_disabled() {
      return !this.link || !this.valid_url || !this.title;
    },
  },

  methods: {
    open_dialog({ id, url, title }, index) {
      this.link = url;
      this.title = title;
      this.reportId = id;
      this.reportIndex = index;
      this.$refs.dialog.open_dialog();
      this.valid_url = true;
    },
    close_dialog() {
      this.$refs.dialog.close_dialog();
    },
    clear_and_close() {
      this.close_dialog();
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    validate_url(event) {
      this.$nextTick(() => {
        this.valid_url = event.target.validity.valid;
      });
    },
    on_dialog_save() {
      this.$store.commit("set_loader", true);
      apiTo
        .updateReport(this.reportId, {
          url: this.link,
          title: this.title,
        })
        .then(({ data }) => {
          this.$emit("report-updated", { data, index: this.reportIndex });
         this.appSnackbar(
            "Report successfully updated.",
            "success"
          );
          this.clear_and_close();
        })
        .finally(() => this.$store.commit("set_loader", false));
    },
  },
};
</script>